import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslatePipe } from '@lf/translate-core';
import { MENU_ITEM } from '@tokens/menu-item.token';

@Component({
  selector: 'app-menu-tooltip',
  standalone: true,
  imports: [TranslatePipe],
  templateUrl: './menu-tooltip.component.html',
  styleUrls: ['./menu-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuTooltipComponent {
  text = inject(MENU_ITEM).name;
}
