import { IconComponent } from '@abm/icon';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslatePipe } from '@lf/translate-core';
import { LinkComponent } from '@lf/ui';
import { AuthService } from '@services/auth/auth.service';
import { USER } from '@tokens/user.token';
import { MenuItem } from '@type/menu.type';
import { SubmenuComponent } from '../submenu-item/submenu.component';

@Component({
  selector: 'app-user-space-submenu',
  standalone: true,
  imports: [IconComponent, TranslatePipe, SubmenuComponent, LinkComponent],
  templateUrl: './user-space-submenu.component.html',
  styleUrls: ['./user-space-submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSpaceSubmenuComponent extends SubmenuComponent {
  private auth = inject(AuthService);

  submenu: MenuItem = {
    name: 'chat',
    url: '',
    restrict: () => false,
    childUrls: [
      {
        name: 'telegram',
        url: 'https://t.me/leafiosupport_bot',
        external: true,
        iconName: 'telegram',
        restrict: () => false,
      },
      // {
      //   name: 'whatsapp',
      //   url: '',
      //   iconName: 'whatsapp',
      //   restrict: () => false,
      // },
      // {
      //   name: 'viber',
      //   url: '',
      //   iconName: 'viber',
      //   restrict: () => false,
      // },
    ],
  };

  user = inject(USER);

  logout() {
    const redirectToDashboard = true;
    this.auth.toLoginPage(redirectToDashboard);
  }
}
