import { IconComponent } from '@abm/icon';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { LinkComponent } from '@lf/ui';
import { MenuItem } from '@type/menu.type';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-menu-item',
  standalone: true,
  imports: [NgClass, IconComponent, LinkComponent],
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent {
  @Input()
  menuItem!: MenuItem;

  isActive = toSignal(
    inject(Router).events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => (event as NavigationEnd).url),
      map((url) => !!this.menuItem.childUrls?.map((u) => u.url).some((u) => url.includes(u))),
    ),
    { initialValue: false },
  );
}
