import { effect, inject, Injectable, Injector } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Validators } from '@angular/forms';
import { PriceType } from '@enums/price-segment.enum';
import { PriceSegmentationViewPipe } from '@permissions/price-segmentation-view/price-segmentation-view.pipe';
import { USER } from '@tokens/user.token';
import { SegmentField } from '@type/price-segment.type';
import { AvailableStrategyField } from '@type/strategy.type';
import { map } from 'rxjs';
import { PriceSegmentApiService } from 'src/app/pages/price-segment/services/price-segment-api/price-segment-api.service';
import { AvailableFieldFormService } from '../available-field-form/available-field-form.service';
import { PriceSegmentationForm, SegmentatioOptions } from '../types';

@Injectable()
export class PriceSegmentationFormService extends AvailableFieldFormService<PriceSegmentationForm, SegmentatioOptions> {
  private injector = inject(Injector);

  private api = this.injector.get(PriceSegmentApiService);

  private user = inject(USER);

  private permissionPipe = inject(PriceSegmentationViewPipe);

  #eff = effect(() => (this.permissionPipe.transform(this.user()) ? this.form.enable() : this.form.disable()));

  options = {
    typeList: () => [{ id: PriceType.Basic, name: 'Basic Price' }],
    sourceList: toSignal(
      this.api
        .getSourceFields()
        .pipe(map((list) => list.map((item) => ({ ...item, id: String(item.id), name: item.localized_name })))),
      { initialValue: [] },
    ),
    fieldList: toSignal(this.api.getFields(), { initialValue: [] }),
  };

  form = this.fb.group({
    categoryId: this.fb.control(this.category.value()?.id || 0),
    type: this.fb.control(this.options.typeList()[0]?.id, [Validators.required]),
    source: this.fb.control(this.options.sourceList()[0]?.id, [Validators.required]),
    fields: this.fb.control<SegmentField[]>([], [Validators.minLength(2)]),
    selectedProps: this.fb.control<AvailableStrategyField[]>(
      [],
      [Validators.required, Validators.minLength(1), Validators.maxLength(5)],
    ),
  });

  isDisabled = toSignal(this.form.controls.type.statusChanges.pipe(map((status) => status === 'DISABLED')), {
    initialValue: false,
  });

  resetForm(): void {
    this.form.reset({
      categoryId: this.category.value()?.id || 0,
      type: undefined,
      source: undefined,
      fields: [],
      selectedProps: [],
    });
  }
}
