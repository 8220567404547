import { Provider } from '@angular/core';
import { PriceSegmentationFieldsService } from '@modules/components/available-fields/services/price-segmentation-fields/price-segmentation-fields.service';
import { PriceSegmentationFormService } from '@modules/components/available-fields/services/price-segmentation-form/price-segmentation-form.service';
import { withAvailableFieldForm, withAvailableFields } from '@modules/components/available-fields/services/provider';
import { PriceSegmentationViewPipe } from '@permissions/price-segmentation-view/price-segmentation-view.pipe';
import { withSelectionModelType } from '@tokens/selection-model.token';
import { withSettingsKeyByCategory } from '@type/user-settings';
import { PriceSegmentApiService } from './services/price-segment-api/price-segment-api.service';
import { PriceSegmentMassUpdateService } from './services/price-segment-mass-update/price-segment-mass-update.service';
import { SegmentationDictionaryService } from './services/segmentation-dictionary/segmentation-dictionary.service';

export const PRICE_SEGMENTATION_PROVIDER: Provider[] = [
  withAvailableFields(PriceSegmentationFieldsService),
  withAvailableFieldForm(PriceSegmentationFormService),
  withSettingsKeyByCategory('priceSegmentation'),
  PriceSegmentApiService,
  SegmentationDictionaryService,
  PriceSegmentMassUpdateService,
  withSelectionModelType('byId'),
  PriceSegmentationViewPipe,
];
