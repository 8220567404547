<div class="header">
  {{ 'main_menu.' + menuItem.name | t | uppercase }}
</div>
@for (item of menuItem.childUrls; track item) {
  @if (!item.restrict()) {
    <lf-link
      class="submenu-item lf-link"
      [external]="!!item.external"
      [link]="[item.url]">
      @if (item.iconName) {
        <abm-icon
          width="1.5rem"
          [src]="'assets/icons/icon-' + item.iconName + '.svg'" />
      }
      {{ 'main_menu.sub_menu.' + item.name | t }}
    </lf-link>
  }
}
